<template>
  <Teleport to="body">
    <div class="modal send-calc__root">
      <div class="modal__body ">
        <button
          class="modal__close"
          @click="onClosePopup"
        >
          <img
            src="~/assets/img/svg/common/close-black.svg"
            alt="close icon"
          >
        </button>
        <div class="send-calc__title-wrap">
          <div class="send-calc__image">
            <img
              src="~/assets/img/svg/common/logo-popup.svg"
              alt="logo"
            >
          </div>
          <div class="send-calc__title">{{ title }}</div>
          <div
            v-if="subtitle"
            class="send-calc__subtitle"
          >
            <div v-html="subtitle"></div>
          </div>
        </div>

        <div class="send-calc__form">
          <div>
            <label class="callback-input__label">Ваше имя</label>
            <input
              v-model="state.name"
              placeholder="ФИО"
              class="input"
            >
            <div
              v-for="(error, index) in $v.name.$errors"
              :key="index"
              class="error-valid"
            >
              {{ error.$message }}
            </div>
          </div>

          <div>
            <label class="callback-input__label">Телефон</label>
            <input
              v-model="state.phone"
              v-maska
              data-maska="+7(###) ###-##-##"
              placeholder="+7(___)___ __ __"
              class="input"
            >
            <div
              v-for="(error, index) in $v.phone.$errors"
              :key="index"
              class="error-valid"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="btnsWrapper">
            <button
              :disabled="isFormSending || !isAccept"
              class="order-popup__btn btn primary --shadow"
              @click="sendOrder"
            >
              {{ state.btnLabel }}
            </button>
          </div>
          <div class="privacy">
            <span class="label-condition">
              Нажимая на кнопку, Вы соглашаетесь с
              <NuxtLink
                target="_blank"
                href="/privacy"
              >
                политикой конфиденциальности
              </NuxtLink>
            </span>
          </div>
        </div>
      </div>
      <div
        class="modal__overlay"
        @click="onClosePopup"
      ></div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { email, helpers, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import { useCallBackPopup } from '~/composables/useCallBackPopup';
import type { FormSendType } from '~/types/Commons';
import { reactive, useRoute } from '#imports';
import { metricsHelper } from '~/utils/forms/metricsHelper';
import parseUtmSessionStorage from "~/utils/forms/parseUtmSessionStorage";

const { onCloseCallBackPopup, callBackState } = useCallBackPopup();

const { $api, $toast, $ym } = useNuxtApp();
const $route = useRoute();

const state = reactive<FormSendType>({
  name: '',
  phone: '',
  // email: '',
  type: callBackState.value.type || 'Заказ обратного звонка',
  title: callBackState.value.title || undefined,
  btnLabel: callBackState.value.btnLabel || 'Отправить'
});

const isAccept = ref(true);

const isFormSending = ref<boolean>(false);

const title = computed(() => callBackState.value.title || 'Отправить заявку');
const subtitle = computed(() => callBackState.value.subtitle || '');

const onClosePopup = () => {
  onCloseCallBackPopup();
};
const rules = {
  name: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  },
  // email: {
  //   email: helpers.withMessage('Неверный формат', email)
  // },
  phone: {
    required: helpers.withMessage('Обязательно к заполнению', required),
    minLength: helpers.withMessage('Неверный формат', minLength(17))
  }
};
const $v = useVuelidate(rules, state);

const sendOrder = async (): Promise<boolean> => {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }
  isFormSending.value = true;

  const newForm: Partial<FormSendType> = {};
  newForm.name = state.name;
  newForm.type = state.type;
  newForm.phone = state.phone;
  newForm.additional_data = parseUtmSessionStorage()

  if (!state.email) {
    delete newForm.email;
  }

  newForm.url = `${window.location.host}${$route.fullPath}`;
  newForm.message = callBackState.value.message;

  const response = await $api.agent.post('/form/send', { body: { ...newForm } })
    .then((res) => res._data)
    .catch((err) => ({ error: errorParserServerResponse(err.response) }));

  if (response.error) {
    $toast.error(response.error);
    isFormSending.value = false;
    return false;
  }

  metricsHelper({
    goal: callBackState.value.ymSendForm,
    name: newForm.name,
    phone: newForm.phone
  });

  $toast.success('Ваша заявка успешно отправлена');
  onCloseCallBackPopup();
  isFormSending.value = false;
  return true;
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.send-calc__root {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;

	.modal__body {
		max-width: 560px;
		padding: 30px;
		background: var(--primary-10);
		border: 2px solid var(--secondary);
    margin: auto;
	}

	.modal__close {
		width: 18px;
		height: 18px;
		top: 32px;

		& > img {
			filter: invert(91%) sepia(59%) saturate(285%) hue-rotate(183deg) brightness(96%) contrast(90%);
		}
	}
}

.send-calc__image{
	margin-bottom: 14px;
}

.callback-input__label {
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 110%;
	margin-bottom: 12px;
	color: var(--primary);
	display: block;
}

.send-calc__title-wrap {
	margin-bottom: 32px;
}

.send-calc__title {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	color: var(--primary);
	padding-right: 40px;
}

.send-calc__subtitle {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	margin-top: 16px;
}

.send-calc__form {
	display: flex;
	flex-direction: column;
	gap: 26px;
}

.btnsWrapper {
	display: flex;
	justify-content: space-between;

	& > button {
		width: 100%;
	}
}

.modal__overlay {
	background: rgb(52 52 52 / 60%);
	backdrop-filter: blur(16px);
	opacity: 1;
}

.privacy {
  margin-top: -12px;

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    input {
      display: flex;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      border-radius: 2px;
      margin-right: 8px;
      background-color: white;
      border-color: white;

      &:checked, &[checked="true"] {
        --chkbg: 0 0% 100%;
        --chkfg: 263 30% 42%;

        background-color: white;
        background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
      }
    }

    span {
      flex: 1;
      color: var(--primary-40);
      font-family: var(--font-family-secondary);
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.24px;

      a:hover {
        color: var(--secondary);
      }
    }
  }
}

@include media('md') {
	.send-calc__root {
		padding: 20px 10px;

		.modal__close {
			top: 20px;
		}

		.modal__body {
			padding: 20px;
		}
	}
}

@include media('sm') {
	.send-calc__subtitle {
		font-size: 14px;
	}
}
</style>
